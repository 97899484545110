import React from 'react';
import './App.css';
import {RouterProvider} from "react-router-dom";
import {Routes} from "./Utils/Routes";
import {ApolloProvider} from "@apollo/client";
import {ApolloClientWrapper} from "./Utils/Apollo";
import {ChakraProvider} from '@chakra-ui/react'


function App() {
    return (
        <>
            <link
                href="https://fonts.googleapis.com/css2?family=Adamina&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                rel="stylesheet"
            />
            <ChakraProvider>
                <ApolloProvider client={ApolloClientWrapper}>
                    <RouterProvider router={Routes}/>
                </ApolloProvider>
            </ChakraProvider>
        </>
    );
}

export default App;
