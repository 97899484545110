import React, {useRef, useState} from 'react';
import './../../App.css';
import {Box} from '@chakra-ui/react';
import SnapScroll from "../../Components/SnapScroll/SnapScroll";
// import {MenuVoice} from "./Components/MenuVoice";
import {FirstBoxBananas} from "./Containers/FirstBox";
import Bananas from "./Components/Bananas";

export const Home = () => {

  const section1 = useRef();
  const section2 = useRef();
  const [speed, setSpeed] = useState<number>(1)


  return (
    <Box
      w={'100vw'}
      h={'100vh'}
      overflow={'hidden'}
    >
      {/*<Box*/}
      {/*  pos={'absolute'}*/}
      {/*  top={'6%'}*/}
      {/*  left={'0'}*/}
      {/*  width={'40px'}*/}
      {/*  height={'80%'}*/}
      {/*  zIndex={'100'}*/}
      {/*  ml={'1%'}*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    width={'100%'}*/}
      {/*    color={'#6E6E6E'}*/}
      {/*    mt={'50%'}*/}
      {/*    display={'flex'}*/}
      {/*    flexDirection={'column'}*/}
      {/*    justifyContent={'space-between'}*/}
      {/*    alignItems={'center'}*/}
      {/*  >*/}
      {/*    <MenuVoice*/}
      {/*      text={'Home'}*/}
      {/*      scrollToSection={section1}*/}
      {/*    />*/}
      {/*    <MenuVoice*/}
      {/*      text={'About'}*/}
      {/*      scrollToSection={section2}*/}
      {/*    />*/}
      {/*    <MenuVoice*/}
      {/*      text={'Services'}*/}
      {/*      scrollToSection={section3}*/}
      {/*    />*/}
      {/*    <MenuVoice*/}
      {/*      text={'Portfolio'}*/}
      {/*      scrollToSection={section3}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</Box>*/}


      <Box pos={'absolute'} top={0} left={0} w={'100vw'} h={'100vh'}>
        <Bananas speed={speed}/>
        <Box
          className={'slider'}
          position={"absolute"}
          bottom={"50%"}
          right={"5vh"}
          fontFamily={'Inter'}
          fontWeight={'400'}
          lineHeight={'1em'}
          letterSpacing={'-0.01em'}
          fontSize={'12px'}
          transform={'rotate(90deg) translate3d(50%, 0, 0)'}
          transformOrigin={'100% 50%'}
          zIndex={9999}

        >
          <input type="range" min="0" max="10" value={speed} step="1" onChange={(e) => setSpeed(Number(e.target.value))} />
        </Box>
      </Box>

      <Box
        pos={'relative'}
        overflowY={'auto'}
        overscrollBehaviorY={"contain"}
        scrollSnapType={"y mandatory"}
        height={"100vh"}
        width={'100%'}
      >
        <SnapScroll
          actualSection={section1}
        >
          {/*<FirstBox/>*/}
          <FirstBoxBananas nextSection={section2}
          />
        </SnapScroll>

        {/*<SnapScroll*/}
        {/*  backgroundPosition={'bottom left'}*/}
        {/*  backgroundRepeat={'no-repeat'}*/}
        {/*  isForSecondaryContent*/}
        {/*  actualSection={section2}*/}
        {/*>*/}
        {/*  <SecondBoxBananas/>*/}
        {/*</SnapScroll>*/}

        {/*<SnapScroll actualSection={section3}>*/}
        {/*  <SecondBox/>*/}
        {/*</SnapScroll>*/}
      </Box>
    </Box>
  )
}

