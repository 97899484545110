import {BsArrowDown} from "react-icons/bs";
import {Box, Button, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import {scrollTo} from "../../../Utils/ScrollTo";

export default function Overlay({nextSection}: any) {
  let [hover] = useState(false)

  return (
    <Box
      fontFamily={'Inter'}
      fontSize={'16px'}
      color={'#9BB0C1'}
    >
      <Box
        pos={'absolute'}
        top={'5vw'}
        left={'5vw'}
      >
        <Text
          padding={0}
          margin={'0 0 0.05em 0'}
          fontFamily={'Ayer Poster'}
          fontWeight={'400'}
          fontSize={'min(18vw, 14em)'}
          lineHeight={'0.85em'}
        >
          COMING
          <br/>
          SOON —
        </Text>
        <p>DOPAMINERS —</p>
      </Box>
      {/*<Box*/}
      {/*  pos={'absolute'}*/}
      {/*  top={'5vw'}*/}
      {/*  right={'10vw'}*/}
      {/*>*/}
      {/*  <Box*/}
      {/*    border={'2px solid #9BB0C1'}*/}
      {/*    h={'40px'}*/}
      {/*    width={'150px'}*/}
      {/*    textTransform={'uppercase'}*/}
      {/*    fontWeight={'700'}*/}
      {/*    fontFamily={'Montserrat'}*/}
      {/*    display={'flex'}*/}
      {/*    justifyContent={'center'}*/}
      {/*    alignItems={'center'}*/}
      {/*    cursor={'pointer'}*/}
      {/*    onClick={(e) => {*/}
      {/*      e.preventDefault();*/}
      {/*      window.location.href = 'mailto:contact@dopaminers.dev';*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Hire US*/}

      {/*  </Box>*/}
      {/*</Box>*/}
      <Box
        pos={'absolute'}
        bottom={'5vw'}
        left={'5vw'}
        width={'25ch'}
        maxW={'40%'}
      >
        Get a taste of happiness with our web design services powered by dopamine-boost!
      </Box>
      {/*<Box*/}
      {/*  className={'button_next'}*/}
      {/*  position={"absolute"}*/}
      {/*  zIndex={'999'}*/}
      {/*  bottom={'-75px'}*/}
      {/*  right={'10vw'}*/}
      {/*  onClick={() => scrollTo(nextSection)}*/}
      {/*  w={"150px"}*/}
      {/*  h={'155px'}*/}
      {/*>*/}
      {/*  <Text*/}
      {/*    fontFamily={"'Montserrat', sans-serif"}*/}
      {/*    fontWeight={'200'}*/}
      {/*    fontSize={'11px'}*/}
      {/*    textTransform={'uppercase'}*/}
      {/*    textAlign={'center'}*/}
      {/*    mb={'5px'}*/}
      {/*  >*/}
      {/*    Sneak Peek*/}
      {/*  </Text>*/}
      {/*  <Button*/}
      {/*    border={'1px dotted #9BB0C1'}*/}
      {/*    borderRadius={'100% 100% 0 0'}*/}
      {/*    margin={"0 auto"}*/}
      {/*    width={"100%"}*/}
      {/*    height={"100%"}*/}
      {/*    zIndex={"2"}*/}
      {/*    backgroundColor={"transparent"}*/}
      {/*    _hover={{*/}
      {/*      backgroundColor: "transparent",*/}
      {/*    }}*/}
      {/*    color={'#353535'}*/}
      {/*    display={'flex'}*/}
      {/*    justifyContent={'center'}*/}
      {/*    alignItems={'start'}*/}
      {/*    fontSize={'20px'}*/}
      {/*  >*/}
      {/*    <Box mt={'25%'}>*/}
      {/*      <BsArrowDown color={'#9BB0C1'}/>*/}
      {/*    </Box>*/}
      {/*  </Button>*/}
      {/*</Box>*/}
      <Box
        pos={'absolute'}
        bottom={'5vw'}
        right={'5vw'}
        width={'30ch'}
        maxW={'40%'}
        textAlign={'center'}
      >
        <Box
          mb={'0.4em'}
          opacity={hover ? 1 : 0}
          transition={'opacity 0.5s ease-in-out'}
        >
          <Text
            fontSize={'1em'}
            textTransform={'uppercase'}
          >
            Have a sneak peek
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
