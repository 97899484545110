//@ts-ignore
import * as THREE from 'three'
import {useRef, useState} from 'react'
import {Canvas, useFrame, useThree} from '@react-three/fiber'
import {Detailed, Environment, useGLTF} from '@react-three/drei'
import {DepthOfField, EffectComposer} from '@react-three/postprocessing'
import {BananasProps} from "../interfaces";
import {Box} from "@chakra-ui/react";

function Banana({index, z, speed}: BananasProps) {
  const ref: {
    current: THREE.Mesh
  } = useRef()

  const {viewport, camera} = useThree()

  const {width, height} = viewport.getCurrentViewport(camera, [0, 0, -z])

  // @ts-ignore
  const {nodes} = useGLTF('/crystal.gltf')

  const [data] = useState({
    y: THREE.MathUtils.randFloatSpread(height * 2),
    x: THREE.MathUtils.randFloatSpread(2),
    spin: THREE.MathUtils.randFloat(8, 12),
    rX: Math.random() * Math.PI,
    rZ: Math.random() * Math.PI
  })

  useFrame((state, dt) => {
    ref?.current?.rotation.set((data.rX += dt / data.spin), Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, (data.rZ += dt / data.spin))

    if (dt < 0.1) {
      ref?.current?.position?.set(index === 0 ? 0 : data.x * width, (data.y += dt * speed), -z)
    }

    if (data.y > height * (index === 0 ? 4 : 1)) {
      data.y = -(height * (index === 0 ? 4 : 1))
    }
  })

  return (
    //@ts-ignore
    <Detailed ref={ref} distances={[0, 35, 80]}>
      <mesh
        geometry={nodes.Crystal?.geometry}
        material={nodes.Crystal?.material}
      />
      <mesh
        geometry={nodes.Small_Crystal_1?.geometry}
        material={nodes.Small_Crystal_1?.material}
      />
      <mesh
        geometry={nodes.Small_Crystal_2?.geometry}
        material={nodes.Small_Crystal_2?.material}
      />
      <mesh
        geometry={nodes.Small_Crystal_3?.geometry}
        material={nodes.Small_Crystal_3?.material}
      />
    </Detailed>
  )
}

export default function Bananas({speed = 1, depth = 120}) {
  return (

    <Box pos={'absolute'} top={0} left={0} w={'100vw'} h={'100vh'}>
      <Canvas
        gl={{
          antialias: true,
        }}
        dpr={[1, 1.5]}
        camera={{position: [0, 0, 10], fov: 20, near: 0.01, far: depth + 15}}
      >
        <color attach="background" args={['#31363F']}/>
        <spotLight
          position={[10, 20, 10]}
          penumbra={0}
          intensity={0.7}
          color="blue"
        />
        {Array.from({length: 15}, (_, i) =>
          <Banana key={i} index={i} z={5} speed={speed}/>
        )}
        {Array.from({length: 10}, (_, i) =>
          <Banana key={i} index={i} z={20} speed={speed}/>
        )}
        {Array.from({length: 60}, (_, i) =>
          <Banana key={i} index={i} z={40} speed={speed}/>
        )}
        {Array.from({length: 30}, (_, i) =>
          <Banana key={i} index={i} z={70} speed={speed}/>
        )}
        {Array.from({length: 30}, (_, i) =>
          <Banana key={i} index={i} z={80} speed={speed}/>
        )}
        {Array.from({length: 70}, (_, i) =>
          <Banana key={i} index={i} z={100} speed={speed}/>
        )}
        <Environment
          files="https://cdn.jsdelivr.net/gh/Sean-Bradley/React-Three-Fiber-Boilerplate@environment/public/img/venice_sunset_1k.hdr"
          background={false}
        />
        <EffectComposer multisampling={0}>
          <DepthOfField target={[0, 0, 60]} focalLength={0.4} bokehScale={14} height={700}/>
        </EffectComposer>
      </Canvas>
    </Box>

  )
}
