import {createBrowserRouter} from "react-router-dom";
import {Home} from "../Containers/Home";
// import {Calendar} from "../Containers/Calendar";

export const Routes = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  // {
  //   path: "/calendar",
  //   element: <Calendar/>,
  // }
]);
