import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {background, Box, Button, Text} from '@chakra-ui/react';
import { BsArrowDown } from 'react-icons/bs';
import {scrollTo} from "../../Utils/ScrollTo";

interface SnapScrollProps {
    actualSection?: any;
    nextSection?: any;
    children?: React.ReactNode;
    buttonVariant?: string;
    isForSecondaryContent?: boolean;
    backgroundImage?: string;
    bg?: string;
    backgroundPosition?: string;
    backgroundRepeat?: string;
}

export default function SnapScroll(props: SnapScrollProps) {

    const {
        isForSecondaryContent = false,
        backgroundImage = null,
      bg= null,
      backgroundRepeat = null,
      backgroundPosition = null
    } = props;
    const headlineRef = useRef();
    useEffect(() => {
        gsap.fromTo(
            // @ts-ignore
            headlineRef.current,
            {
                autoAlpha: 0,
                y: -20,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 1,
                ease: "power3.out",
                scrollTrigger: {
                    scroller: ".container",
                    trigger: headlineRef.current,
                    start: "top 60%",
                    end: "bottom 0%",
                    toggleActions: "play none restart reverse",
                },
            }
        );
        return () => {
        };
    }, []);
    return (
        <>

            {/* @ts-ignore */}
            <Box ref={props?.actualSection}
                 {...(isForSecondaryContent? {
                     minHeight: "100vh"
                 } : {
                     height: "100vh"
                 })}
                 {...(background? {
                     bg,
                     backgroundImage,
                     backgroundPosition,
                     backgroundRepeat,
                 } : {})}
                 width={"100%"}
                 display={"flex"}
                 justifyContent={"center"}
                 alignItems={"center"}
                 scrollSnapAlign={isForSecondaryContent ? "start" : "center"}
                 position={"relative"}
                 overflow={"hidden"}
            >
                {props?.children}

                {props?.buttonVariant && props?.buttonVariant === 'home' && (
                    <Box
                        className={'button_next'}
                        position={"absolute"}
                        zIndex={'9990000'}
                        bottom={'-75px'}
                        right={'30%'}
                        onClick={() => scrollTo(props?.nextSection)}
                        w={"150px"}
                        h={'155px'}
                    >
                        <Text
                            fontFamily={"'Montserrat', sans-serif"}
                            fontWeight={'200'}
                            fontSize={'11px'}
                            textTransform={'uppercase'}
                            textAlign={'center'}
                            mb={'5px'}
                        >
                            Down
                        </Text>
                        <Button
                            border={'1px dotted black'}
                            borderRadius={'100% 100% 0 0'}
                            margin={"0 auto"}
                            width={"100%"}
                            height={"100%"}
                            zIndex={"2"}
                            backgroundColor={"transparent"}
                            _hover={{
                                backgroundColor: "transparent",
                            }}
                            color={'#353535'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'start'}
                            fontSize={'20px'}
                        >
                            <Box mt={'25%'}>
                                <BsArrowDown/>
                            </Box>
                        </Button>
                    </Box>
                )}

            </Box>
        </>
    );
}
