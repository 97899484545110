import React, {Suspense} from "react";
import {Box, Image, Text} from "@chakra-ui/react";
import mockup from "../../../assets/images/Home/mockup1.png";
import home_bg_1 from "../../../assets/images/Home/home_bg_1.png";
import strawberry_top from "../../../assets/images/Home/strawberry_top.png";
import strawberry_middle from "../../../assets/images/Home/strawberry_middle.png";
import strawberry_bottom from "../../../assets/images/Home/strawberry_bottom.png";
import Overlay from "../Components/Overlay";

export const FirstBox = () => {

  const THRESHOLD = 15;

  let [transformTop, setTransformTop] = React.useState('');
  let [transformMiddle, setTransformMiddle] = React.useState('');
  let [transformBottom, setTransformBottom] = React.useState('');

  const handleHover = (e: any) => {
    const {clientX, clientY, currentTarget} = e;
    const {clientWidth, clientHeight, offsetLeft, offsetTop} = currentTarget;

    const horizontal = (clientX - offsetLeft) / clientWidth;
    const vertical = (clientY - offsetTop) / clientHeight;

    const rotateXTop = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
    const rotateYTop = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

    const rotateXMid = (THRESHOLD / 7 - horizontal * THRESHOLD).toFixed(2);
    const rotateYMid = (vertical * THRESHOLD - THRESHOLD / 5).toFixed(2);

    const rotateXBot = (THRESHOLD / 3 - horizontal * THRESHOLD).toFixed(2);
    const rotateYBot = (vertical * THRESHOLD - THRESHOLD / 1.5).toFixed(2);


    setTransformTop(`perspective(${clientWidth / 4}px) rotateX(${rotateXTop}deg) rotateY(${rotateYTop}deg)`);
    setTransformMiddle(`perspective(${clientWidth / 4}px) rotateX(${rotateXMid}deg) rotateY(${rotateYMid}deg)`);
    setTransformBottom(`perspective(${clientWidth / 4}px) rotateX(${rotateXBot}deg) rotateY(${rotateYBot}deg)`);
  }

  return (
    <Box
      w={'100vw'}
      h={'100vh'}
      pos={'relative'}
      overflow={'hidden'}
      onMouseMove={(e) => handleHover(e)}
    >
      <Box
        backgroundImage={mockup}
        backgroundSize={'cover'}
        backgroundPosition={'top left'}
        position={'absolute'}
        zIndex={'500'}
        width={'100%'}
        height={'100%'}
        pointerEvents={'none'}
        opacity={'0'}
      />

      {/*RIGHT BOX*/}
      <Box
        w={'750px'}
        h={'100%'}
        backgroundImage={home_bg_1}
        backgroundSize={'100% 105%'}
        backgroundPosition={'center center'}
        pos={'absolute'}
        top={'0'}
        right={'0'}
        zIndex={'0'}
      >
        <Box
          background={'#710000'}
          w={"350px"}
          h={'43px'}
          pos={'absolute'}
          top={'6%'}
          left={'18%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Text
            fontFamily={'Montserrat'}
            fontWeight={'800'}
            fontSize={'17px'}
            color={"#A6A6A6"}
            textTransform={'uppercase'}
            ml={'190px'}
          >
            Coming Soon
          </Text>
        </Box>
        <Text
          fontFamily={'Montserrat'}
          fontWeight={'800'}
          fontSize={'62px'}
          textTransform={'uppercase'}
          pos={'absolute'}
          top={'25.5%'}
          left={'49%'}
          lineHeight={'119%'}
          w={'555px'}
          color={'rgba(130,112,69,0.6)'}
        >
          Future forward
        </Text>

        <Box
          className={'dot_grid'}
          backgroundImage={"radial-gradient(#897C7C 1px, transparent 0)"}
          backgroundSize={"70px 70px"}
          backgroundPosition={"10px 10px"}
          width={'47%'}
          height={'50%'}
          pos={'absolute'}
          top={'45%'}
          right={'5.5%'}
          w={'555px'}
        >
          <Text
            color={'#FF4848'}
            fontFamily={'Montserrat'}
            fontWeight={'200'}
            textTransform={'uppercase'}
            fontSize={'15px'}
            transform={'rotate(-4.11deg)'}
            pos={'absolute'}
            top={'6%'}
            right={'-12%'}
            whiteSpace={'nowrap'}
          >
            3d, virtual reality, new technology, games
          </Text>

        </Box>
      </Box>

      {/*//LEFT BOX*/}
      <Box
        w={'100%'}
        h={'100%'}
      >
        <Box
          w={'calc(100% - 180px)'}
          h={'100%'}
          bg={'#D5CCB6'}
          pos={'absolute'}
          zIndex={'1'}
          clipPath={"polygon(0 0%, 76% 0%, 100% 100%, 0% 100%)"}
        >
          <Box
            w={'100%'}
            mt={'3%'}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            px={'25px'}
            ml={'1%'}
          >

            <Box h={'67px'} ml={'2%'}>
              <Text
                fontFamily={'Montserrat'}
                fontWeight={'800'}
                fontSize={'29px'}
                className={'text_stroke_black'}
                textTransform={'uppercase'}
                w={'555px'}
                mt={'5px'}
                ml={'7px'}
              >
                DOPA<span className={'text_stroke_black_text'}>MINERS</span>
              </Text>
              <Text
                fontFamily={'Montserrat'}
                fontWeight={'400'}
                fontSize={'17px'}
                textTransform={'lowercase'}
                w={'555px'}
                mt={'-10px'}
                ml={'4px'}
                color={'#827045'}
              >
                all you need in web
              </Text>
            </Box>


          </Box>

          <Box
            w={'100%'}
            h={'100%'}
            mt={'2%'}
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            ml={'1%'}
          >
            <Box
              w={'100%'}
              h={'100%'}
              cursor={'default'}
              userSelect={'none'}
              pos={'relative'}
            >

              <Box
                className={'dot_grid'}
                backgroundImage={"radial-gradient(#897C7C 1px, transparent 0)"}
                backgroundSize={"70px 70px"}
                backgroundPosition={"10px 10px"}
                width={'47%'}
                height={'50%'}
                pos={'absolute'}
                mt={'-4%'}/>
              <Box
                w={'70%'}
                h={'100%'}
                ml={'12%'}
                pos={'relative'}
              >
                <Box
                  className={'strawberry'}
                  width={'75%'}
                  height={'87%'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  flexDir={'column'}
                  pos={'absolute'}
                  transition={'all 0.3s ease'}
                  zIndex={100}
                >
                  <Image src={strawberry_top} w={'39%'} pos={'absolute'} left={'33%'} top={'1%'}
                         transform={transformTop}/>
                  <Image src={strawberry_middle} w={'34.5%'} pos={'absolute'} top={'31.5%'}
                         left={'36%'}
                         transform={transformMiddle}/>
                  <Image src={strawberry_bottom} w={'29%'}
                         pos={'absolute'} top={'54.5%'} left={'41.5%'}
                         transform={transformBottom}/>
                </Box>

                <Box
                  className={'strawberry_text text_stroke_strawberry_text'}
                  width={'100%'}
                  height={'80%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  flexDir={'column'}
                  pos={'absolute'}
                  fontFamily={'Adamina'}
                  fontWeight={'400'}
                  fontSize={'6.5vw'}
                  textTransform={'uppercase'}
                  ml={'7%'}
                  lineHeight={'136%'}
                  pointerEvents={'none'}
                >
                  <Text>Experience</Text>
                  <Text>The <span
                    className={'text_stroke_strawberry_text_berry'}>Berry</span></Text>
                  <Text>BEST</Text>

                </Box>

                <Box
                  className={'strawberry_text text_stroke_strawberry_text_light strawberry_mask'}
                  width={'100%'}
                  height={'80%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  flexDir={'column'}
                  pos={'absolute'}
                  fontFamily={'Adamina'}
                  fontWeight={'400'}
                  fontSize={'6.5vw'}
                  textTransform={'uppercase'}
                  ml={'7%'}
                  lineHeight={'125%'}
                  zIndex={120}
                  pointerEvents={'none'}
                >
                  <Text>Experience</Text>
                  <Text>The Berry</Text>
                  <Text mb={'17px'}>BEST</Text>
                </Box>


                <Box
                  width={'100%'}
                  height={'50px'}
                  pos={'absolute'}
                  top={'65%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  left={'-3%'}
                >
                  <Box
                    background={"-webkit-linear-gradient(left, #B00D0D, #FF9601);"}
                    padding={'10px 60px 10px 20px'}
                    textTransform={'uppercase'}
                    fontFamily={'Montserrat'}
                    fontWeight={'700'}
                    fontSize={'15px'}
                    color={'#EFE5CB'}
                    clipPath={"polygon(85% 0, 93% 50%, 85% 100%, 0% 100%, 0 50%, 0% 0%)"}

                  >
                    stand out from the patch

                  </Box>

                </Box>
              </Box>
            </Box>


          </Box>

        </Box>

      </Box>
    </Box>
  );
}

export const FirstBoxBananas = ({nextSection}: any) => {

  return (
    <>
      <Suspense fallback={null}>
        <Box
          pos={'absolute'}
          top={'0'}
          left={'0'}
          w={'100%'}
          h={'100%'}
          pointerEvents={'none'}
          animation={'fade 3s normal forwards ease-in-out'}
        />
      </Suspense>
      <Overlay nextSection={nextSection}/>
    </>
  )
}
